import React from "react";
import "./SHERintro.css"; // Import the CSS file for styling
import Button from '@mui/material/Button';



export default function SHERintro() {
    const handleButtonClick = () => {
        // alert('Application is opening...');
        window.location.href = 'https://youtu.be/7a4RCswc3Wc?si=1AyWbptllr3Sou4_';
    };
    const handleButtonClickGform = () => {
        // alert('Application is opening...');
        window.location.href = 'https://forms.gle/8oo65LEmvxaFdpSg8';
    };
    // const handleButtonClick = () => {
    //     // alert('Application is opening...');
    //     window.location.href = 'https://drive.google.com/file/d/1ZV4fQAsYQjtcRq0J0Z5Yvz_3i8TDqUQt/view?usp=sharing';
    // };
    return (
        <div >
            <div className="SHERintro-container"> {/* Use a class for styling */}
                <h2 className="SHERintro-title">"Dedicated to solve real life problems to empower Humanity, Education and Research"</h2> {/* Use a class for styling */}
            </div>
            <div className="button-container">
                <Button variant="contained" onClick={handleButtonClickGform}>
                    Book 5 min conversession for free
                </Button>
            </div>


            <div className="SHERintro-title" >Manager APP version 4.0.4 & Aspirant APP version 4.0.0 are live now!</div>

            <div className="button-container">
                <Button variant="contained" onClick={handleButtonClick}>
                    30+ Key Features
                </Button>
            </div>

            <div className=""> {/* Use a class for styling */}
                <h2 className="SHERintro-title step">A Step Towards Library digitalization across India!</h2> {/* Use a class for styling */}
            </div>
        </div>
    );
}
