import React from "react";
import './ManageLibs.css'
import VideoCard from "../../Blog/video-cards";


const SliderData = [
    {
        image: '/assets/adminapp/home.jpg' // Corrected image path
    },
    {
        image: '/assets/adminapp/aspirant.jpg'
    },
    {
        image: '/assets/adminapp/seatBooking.jpg'
    },
    {
        image: '/assets/adminapp/libraryProfile.jpg'
    },
    {
        image: '/assets/adminapp/libraryQR.jpg'
    }
];

const AspirantAppImages = [

    {
        image: '/assets/aspirantapp/signup.jpg' // Corrected image path
    },
    {
        image: '/assets/aspirantapp/login.jpg'
    },
    {
        image: '/assets/aspirantapp/home.jpg'
    },
    {
        image: '/assets/aspirantapp/scanQR.jpg'
    },
    {
        image: '/assets/aspirantapp/attendenceDone.jpg'
    },
    {
        image: '/assets/aspirantapp/profile.jpg'
    }
];


export default function ManageLibsServices() {
    return <>

        <div className="LibraryDiv">
            <div className="membership-box">
                <img className="membership-image" src="assets/Offer/partner.png" alt="Library" />
                <div className="membership-text">
                    <h2 className="title1"><b>Become our library partner</b></h2>
                    <h3 className="title1">Affordable library membership fee</h3>

                    <h3 className="title2">Enjoy 3 days trial for free</h3>
                </div>
            </div>

            <h2 className="title1"><b>Library Management Software Pricing Plan</b></h2>

            <div class="libSerIntro">
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            1 Year <span class="enclosed">₹ 3000 + 18% GST</span>
                        </h1>
                    </div>
                </div>
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            6 Months <span class="enclosed">₹ 1600 + 18% GST</span>
                        </h1>
                    </div>
                </div>
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            3 Months  <span class="enclosed">₹ 900 + 18% GST</span>
                        </h1>
                    </div>
                </div>

            </div>


            <h2 className="title1"><b>Smart Door Lock with Integrated Software</b></h2>

            <div class="libSerIntro">
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            Price <span class="enclosed">₹ 5800 + 18% GST </span>
                        </h1>
                    </div>
                </div>
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            Guarantee <span class="enclosed"> 3 Months</span>
                        </h1>
                    </div>
                </div>
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            Feature <span class="enclosed">Support 1K Fingerprints + QR Lock + App Control + Emergency Button</span>
                        </h1>
                    </div>
                </div>
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            <a href="https://github.com/S-H-E-R-Development/Privacy-Policy-/blob/main/Biometric%20Door%20Lock%20System.md">  Privacy Policy </a>
                        </h1>
                    </div>
                </div>
            </div>
            <h2 className="title1"><b>Library Website Pricing Plan</b></h2>

            <div class="libSerIntro">
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            Price <span class="enclosed">₹ 1999 + 18% GST </span>
                        </h1>
                    </div>
                </div>
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            24/7 Technical Support <span class="enclosed"> 1 Year</span>
                        </h1>
                    </div>
                </div>
                <div class="libSerIntroDiv card">
                    <div class="card00">
                        <h1>
                            Excluded <span class="enclosed">Domain name</span>
                        </h1>
                    </div>
                </div>
            </div>
            <div className="vdo-blog-container">
                <VideoCard url="https://youtu.be/v-RjAiazf-c?si=srG5vBW0ZHk4UHWA" />
            </div>
            <h1 className="title" > Library Management System</h1>

            <div className="labDec">Easily manage your library and streamline your all library management tasks efficiently along with digitalization of your library with managelibs.com.</div>
            <ul className="libSerIntro">
                <li>
                    <div className="libSerIntroDiv card hoverEffect1">
                        <h2>EM Door Lock Software</h2>
                        <div className="divline"></div>
                        <p>EM Door Lock operate on managelibs' software</p>
                        <p>It comes with the Library Management Software Package.</p>
                        <button className="btn2 hoverEffect"><a href="https://drive.google.com/file/d/1hQkr761nT_ltqnTABxW5n2ped6E22Ryj/view?usp=sharing">   Download Manager APP</a></button>

                    </div>
                </li>

                <li>
                    <div className="libSerIntroDiv card hoverEffect1">
                        <h2>Library Manager App</h2>
                        <div className="divline"></div>
                        <p>Real-time monitoring, maintenance, seat booking, door lock handling and more...</p>
                        <p>Exclusively for library owners</p>
                        <button className="btn2 hoverEffect"><a href="https://drive.google.com/file/d/1cf9yzy9vP9vAgz-ICCP6-XhM7mDIye55/view?usp=sharing">   Download Manager APP</a></button>

                    </div>
                </li>

                <li>
                    <div className="libSerIntroDiv card hoverEffect1">
                        <h2>Library Aspirant App</h2>
                        <div className="divline"></div>
                        <p>Door opening + QR-based attendance, fee notifications, time up alert and more..</p>

                        <p>Exclusively for library aspirant</p>
                        <button className="btn2 hoverEffect"><a href="https://play.google.com/store/apps/details?id=com.managelibs.aspirantapp&pcampaignid=web_share">Download Aspirant APP</a></button>

                    </div>
                </li>

            </ul>

            <div className="secondDiv">
                <h2 className="title1 ">Why should you use Library Management System ?</h2>
                <p className="ParaDef">Streamline library tasks with managelibs.com: Simplify registration, live monitoring, QR-based attendance, Quiz-facilities and more...</p>
                <ul className="servicesUl">
                    <li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/doorlock.png" alt="door lock" />
                                <h3>EM Door Lock System</h3>
                                <p>Increase Safety & Security</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/fireSensor.png" alt="open enrollment" />
                                <h3>Temperature Sensor</h3>
                                <p>Auto opening of EM Door if fire caught and Temperature rise above 60 degree C</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/dueAlert.png" alt="open enrollment" />
                                <h3>Payment Reminder</h3>
                                <p>If payment is not upto date, EM Door won't open.</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/support.png" alt="open enrollment" />
                                <h3>Support</h3>
                                <p>24/7 Technical Support</p>
                            </div>
                        </li>
                    </li>
                    <li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/open-enrollment.png" alt="open enrollment" />
                                <h3>Aspirant Registration</h3>
                                <p>Register in a flash, no hassle involved</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/rights.png" alt="open enrollment" />
                                <h3>Effortless Maintenance</h3>
                                <p>Keep your registration up-to-date effortlessly</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/research.png" alt="open enrollment" />
                                <h3>Instant Search</h3>
                                <p>Find aspirant details instantly, saving time</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/immigration.png" alt="open enrollment" />
                                <h3>Attendance Report</h3>
                                <p>6 Month Attendance Report </p>
                            </div>
                        </li>


                    </li>

                    <li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/office-desk.png" alt="open enrollment" />
                                <h3>Quick Seat Finder</h3>
                                <p>Locate vacant seats with ease, slot by slot</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/management.png" alt="open enrollment" />
                                <h3>Smooth Aspirant Management</h3>
                                <p>Handle active aspirants seamlessly for a hassle-free experience</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/payment-notification.png" alt="open enrollment" />
                                <h3>Timely Payment Alerts</h3>
                                <p>Get notified promptly about pending payments</p>
                            </div>
                        </li>

                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/phone-call.png" alt="open enrollment" />
                                <h3>Direct calling</h3>
                                <p>Direct calling from the App</p>
                            </div>
                        </li>

                    </li>

                    <li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/qr-scan.png" alt="open enrollment" />
                                <h3>QR Attendance</h3>
                                <p>Take attendance in a snap with our QR system</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/monitoring.png" alt="open enrollment" />
                                <h3> Real-Time Monitoring</h3>
                                <p>Keep an eye on the library's status in real-time</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/student-card.png" alt="open enrollment" />
                                <h3> Professional Cards</h3>
                                <p>Access and share elegant library cards effortlessly</p>
                            </div>
                        </li>
                        <li>
                            <div className="FeatureDiv hoverEffect1">
                                <img className="FeatureIcon" src="assets/mangIcons/photo-album.png" alt="open enrollment" />
                                <h3>Gallery</h3>
                                <p>Share Gallery to others</p>
                            </div>
                        </li>




                    </li>
                </ul>
            </div>


            <div >
                <h2 className="title1 ">Impact of managelibs.com on your library-growth</h2>
                <p className="ParaDef">Experience library growth with managelibs.com: Streamlined operations, user-friendly apps, and real-time monitoring propel libraries into the digital age, fostering learning and innovation</p>

                <ul className="servicesUl">

                    <li>
                        <div className="FeatureDiv1 hoverEffect card">
                            <img className="FeatureIcon" src="assets/mangIcons/qr-scan.png" alt="open enrollment" />
                            <h3>Swift QR Attendance</h3>
                            <p>Easily track attendance using QR codes, saving time for staff and users</p>
                        </div>
                    </li>
                    <li>
                        <div className="FeatureDiv1 hoverEffect card">
                            <img className="FeatureIcon" src="assets/mangIcons/payment-notification.png" alt="open enrollment" />
                            <h3>Prompt Payment Notifications</h3>
                            <p>Get instant alerts for pending payments, ensuring smooth financial operations</p>
                        </div>
                    </li>
                    <li>
                        <div className="FeatureDiv1 hoverEffect card">
                            <img className="FeatureIcon" src="assets/mangIcons/monitoring.png" alt="open enrollment" />
                            <h3>Timely Library Time Alerts</h3>
                            <p>Receive quick notifications before library time expires, helping users manage their time effectively</p>
                        </div>
                    </li>
                    <li>
                        <div className="FeatureDiv1 hoverEffect card">
                            <img className="FeatureIcon" src="assets/mangIcons/student-card.png" alt="open enrollment" />
                            <h3>Easy Library ID-Card Access</h3>
                            <p>Quickly obtain your library ID-card for enhanced access to resources and services</p>
                        </div>
                    </li>

                </ul>
                <ul className="servicesUl">
                    <li>
                        <div className="FeatureDiv1 hoverEffect card">
                            <img className="FeatureIcon" src="assets/mangIcons/management.png" alt="open enrollment" />
                            <h3>Library Stopwatch Feature</h3>
                            <p>Use the built-in stopwatch to manage time effectively during library sessions, maximizing productivity</p>
                        </div>
                    </li>

                    <li>
                        <div className="FeatureDiv1 hoverEffect card">
                            <img className="FeatureIcon" src="assets/mangIcons/student-card.png" alt="open enrollment" />
                            <h3>Create Dream Quiz</h3>
                            <p>Create Your Own Dream Quiz and Share with others</p>
                        </div>
                    </li>
                    <li>
                        <div className="FeatureDiv1 hoverEffect card">
                            <img className="FeatureIcon" src="assets/mangIcons/management.png" alt="open enrollment" />
                            <h3>Dream Quiz</h3>
                            <p>Access to Dream Quiz</p>
                        </div>
                    </li>
                    <li>
                        <div className="FeatureDiv1 hoverEffect card">
                            <img className="FeatureIcon" src="assets/mangIcons/monitoring.png" alt="open enrollment" />
                            <h3>Performance Analytics</h3>
                            <p>See Your Performance Analytics and improve your speed, accuracy and overall Performance</p>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="connection-div">
                <h2 className="title1 "></h2>
                <ul>
                    <li className="containerr card hoverEffect1">
                        <img src="assets/mangeAspIcons/server-check.png" />
                        <p><span>Centralized Hub - </span>All-in-one solution for library management needs. </p>
                    </li>
                    <li className="containerr card hoverEffect1">
                        <img src="assets/mangeAspIcons/profile.png" />

                        <p><span>Library Registration - </span>Easily register your library. </p>
                    </li>
                    <li className="containerr card hoverEffect1">
                        <img src="assets/mangeAspIcons/setting.png" />

                        <p><span>Account Management - </span>Seamlessly manage accounts. </p>
                    </li>
                    <li className="containerr card hoverEffect1">
                        <img src="assets/mangeAspIcons/research.png" />

                        <p><span>Operations Oversight - </span>Efficiently oversee library operations. </p>
                    </li>
                    <li className="containerr card hoverEffect1">
                        <img src="assets/mangeAspIcons/integration.png" />

                        <p><span>Manager APP Integration - </span>Connect to our Manager APP for real-time monitoring and management. </p>
                    </li>
                    <li className="containerr card hoverEffect1">
                        <img src="assets/mangeAspIcons/girl.png" />

                        <p><span>Aspirant APP - </span>Empower library users with features like attendance tracking and fee notifications. </p>
                    </li>
                    <li className="containerr card hoverEffect1">
                        <img src="assets/mangeAspIcons/data.png" />

                        <p><span>Interconnected Platforms - </span>Revolutionize library management with seamless communication. </p>
                    </li>
                    <li className="containerr card hoverEffect1">
                        <img src="assets/mangeAspIcons/wand.png" />

                        <p><span>Enhanced User Experiences - </span>Improve user experiences with integrated features. </p>
                    </li>
                </ul>
            </div>
        </div >
    </>
}